const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.nsr.forwoodsafety.com',
    USER_TOKEN_URL: 'https://a4yxm13r3f.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://9mqz9tikx1.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.2',
    HOSTNAME: 'https://admin.staging.nsr.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.nsr.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.nsr.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.nsr.forwoodsafety.com',
    WEBSOCKET: 'wss://dvldmv3ov7.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
